import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import useAuth from 'hooks/useAuth';

const ProtectedRoute = ({ children, roles }) => {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to="/" replace />;
    }

    const canAccess = roles.includes(user.funcao);

    if (!canAccess) {
        return <Navigate to="/" replace />;
    }

    return children;
};

ProtectedRoute.propTypes = {
    children: PropTypes.node.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ProtectedRoute;
