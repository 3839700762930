import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn, user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login', { replace: true });
            return;
        }
        if (user && user.flgconfirmado === 'N' && location.pathname !== '/confirmar-codigo') {
            navigate('/confirmar-codigo', { replace: true });
        } else if (user && user.flgconfirmado === 'S' && location.pathname === '/confirmar-codigo') {
            navigate('/dashboard', { replace: true });
        }
    }, [isLoggedIn, user, navigate, location]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
