// material-ui
import { useTheme } from '@mui/material/styles';

import logo from 'assets/images/logo.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        <img src={theme.palette.mode === 'dark' ? logo : logo} alt="Berry" width="100" />
    );
};

export default Logo;
