import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, UPDATE_PROFILE } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { authApi, mainApi } from 'utils/api';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken, userId) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        localStorage.setItem('userId', userId);
        mainApi.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        localStorage.removeItem('userId');
        delete mainApi.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                const userId = window.localStorage.getItem('userId');

                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken, userId);
                    const userResponse = await mainApi.get(`/usuario/${userId}`);
                    const { flgtipo: funcao } = userResponse.data;

                    const user = {
                        userId,
                        funcao,
                        ...userResponse.data
                    };
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (inputPhone, password) => {
        const authResponse = await authApi.post('/usuario/auth', { telefone: inputPhone, senha: password });
        const { token: serviceToken, idusuario: userId, funcao } = authResponse.data;

        setSession(serviceToken, userId);

        const userResponse = await mainApi.get(`/usuario/${userId}`);

        const user = {
            userId,
            funcao,
            ...userResponse.data
        };

        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };

    const register = async (email, senha, nome, telefone, flgtipo, flgativo) => {
        await mainApi.post('/usuario', {
            email,
            senha,
            nome,
            telefone,
            flgtipo,
            flgativo
        });

        const authResponse = await authApi.post('/usuario/auth', { telefone, senha });
        const { token: serviceToken, idusuario: userId, funcao } = authResponse.data;

        setSession(serviceToken, userId);

        const userResponse = await mainApi.get(`/usuario/${userId}`);

        const user = {
            userId,
            funcao,
            ...userResponse.data
        };

        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };

    const novaempresa = async (
        email,
        senha,
        nome,
        telefone,
        flgtipo,
        flgativo,
        nmempresa,
        nmfantasia,
        cnpj,
        endereco,
        numero,
        complemento,
        bairro,
        cep,
        cidade,
        estado,
        pais
    ) => {
        await mainApi.post('/novaempresa', {
            email,
            senha,
            nome,
            telefone,
            flgtipo,
            flgativo,
            nmempresa,
            nmfantasia,
            cnpj,
            endereco,
            numero,
            complemento,
            bairro,
            cep,
            cidade,
            estado,
            pais
        });

        const authResponse = await authApi.post('/usuario/auth', { telefone, senha });
        const { token: serviceToken, idusuario: userId, funcao } = authResponse.data;

        setSession(serviceToken, userId);

        const userResponse = await mainApi.get(`/usuario/${userId}`);

        const user = {
            userId,
            funcao,
            ...userResponse.data
        };

        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };

    const logout = () => {
        setSession(null, null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = (nrotelefone) => mainApi.post('/resetasenha', { nrotelefone });

    const fetchProfile = async () => {
        const userId = window.localStorage.getItem('userId');
        const userResponse = await mainApi.get(`/usuario/${userId}`);
        const { flgtipo: funcao } = userResponse.data;

        const user = {
            userId,
            funcao,
            ...userResponse.data
        };
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };

    const updateProfile = async (userData) => {
        await mainApi.put('/usuario', userData);

        const userResponse = await mainApi.get(`/usuario/${userData.idusuario}`);

        const user = {
            userId: userData.idusuario,
            funcao: userData.flgtipo,
            ...userResponse.data
        };

        dispatch({
            type: UPDATE_PROFILE,
            payload: {
                user
            }
        });
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, fetchProfile, updateProfile, novaempresa }}>
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
