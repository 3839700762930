// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard,
    IconUser,
    IconUsers,
    IconCurrencyDollar,
    IconSignature,
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconTag,
    IconFiles,
    IconFileUpload,
    IconBuilding,
    IconBuildingBank,
    IconBuildingStore,
    IconMap
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconUsers,
    IconUser,
    IconCurrencyDollar,
    IconSignature,
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconFiles,
    IconTag,
    IconFileUpload,
    IconBuilding,
    IconBuildingBank,
    IconBuildingStore,
    IconMap
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'perfil',
            title: 'Perfil',
            type: 'item',
            url: '/perfil',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'config',
            title: 'Taxas Básicas',
            type: 'item',
            url: '/config',
            icon: icons.IconBuildingBank,
            breadcrumbs: false,
            protectedTo: ['M']
        },
        {
            id: 'empresa',
            title: 'Empresas',
            type: 'item',
            url: '/empresa',
            icon: icons.IconBuilding,
            breadcrumbs: false,
            protectedTo: ['M', 'U']
        },
        {
            id: 'contrato',
            title: 'Contratos',
            type: 'item',
            url: '/contrato',
            icon: icons.IconSignature,
            breadcrumbs: false,
            protectedTo: ['M', 'U']
        },
        {
            id: 'usuario',
            title: 'Usuários',
            type: 'item',
            url: '/usuario',
            icon: icons.IconUsers,
            breadcrumbs: false,
            protectedTo: ['M', 'U']
        },
        {
            id: 'transmissor',
            title: 'Pontos de Venda',
            type: 'item',
            url: '/transmissor',
            icon: icons.IconBuildingStore,
            breadcrumbs: false,
            protectedTo: ['M', 'U', 'E']
        },
        {
            id: 'faturamento',
            title: 'Faturamento',
            type: 'item',
            url: '/faturamento',
            icon: icons.IconCurrencyDollar,
            breadcrumbs: false,
            protectedTo: ['M', 'E']
        },
        {
            id: 'categoria',
            title: 'Categoria',
            type: 'item',
            url: '/categoria',
            icon: icons.IconTag,
            breadcrumbs: false
        },
        {
            id: 'documento',
            title: 'Meus Cupons',
            type: 'item',
            url: '/documento',
            icon: icons.IconFiles,
            breadcrumbs: false
        },
        {
            id: 'incluir-documento',
            title: 'Incluir Cupom',
            type: 'item',
            url: '/incluir-documento',
            icon: icons.IconFileUpload,
            breadcrumbs: false,
            protectedTo: ['M', 'U', 'C']
        },
        {
            id: 'tutorial',
            title: 'Como Integrar',
            type: 'item',
            url: '/tutorial',
            icon: icons.IconHelp,
            breadcrumbs: false,
            protectedTo: ['M', 'U', 'E']
        },
        {
            id: 'parceiros',
            title: 'Parceiros',
            type: 'item',
            url: '/parceiros',
            icon: icons.IconMap,
            breadcrumbs: false
        }
    ]
};

export default other;
/**
,
        {
            id: 'sample-page',
            title: <FormattedMessage id="sample-page" />,
            type: 'item',
            url: '/sample-page',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'documentation',
            title: <FormattedMessage id="documentation" />,
            type: 'item',
            url: 'https://codedthemes.gitbook.io/berry/',
            icon: icons.IconHelp,
            external: true,
            target: true
        },
        {
            id: 'roadmap',
            title: <FormattedMessage id="roadmap" />,
            type: 'item',
            url: 'https://codedthemes.gitbook.io/berry/roadmap',
            icon: icons.IconSitemap,
            external: true,
            target: true
        }  
 */
