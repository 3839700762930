import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import ProtectedRoute from 'utils/route-guard/ProtectedRoute';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import NavMotion from 'layout/NavMotion';

// sample page routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const Perfil = Loadable(lazy(() => import('views/perfil')));
const Tutorial = Loadable(lazy(() => import('views/tutorial')));
const Empresa = Loadable(lazy(() => import('views/empresa')));
const Contrato = Loadable(lazy(() => import('views/contrato')));
const Usuario = Loadable(lazy(() => import('views/usuario')));
const Transmissor = Loadable(lazy(() => import('views/transmissor')));
const Faturamento = Loadable(lazy(() => import('views/faturamento')));
const Documento = Loadable(lazy(() => import('views/documento')));
const IncluirDocumento = Loadable(lazy(() => import('views/incluir-documento')));
const Parceiros = Loadable(lazy(() => import('views/parceiros')));
const Categoria = Loadable(lazy(() => import('views/categoria')));
const Config = Loadable(lazy(() => import('views/config')));
const ConfiramrCodigo = Loadable(lazy(() => import('views/confirmar-codigo')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <AuthGuard>
                    <MainLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: '/dashboard',
                    element: <Dashboard />
                },
                {
                    path: '/perfil',
                    element: <Perfil />
                },
                {
                    path: '/config',
                    element: (
                        <ProtectedRoute roles={['M']}>
                            <Config />
                        </ProtectedRoute>
                    )
                },
                {
                    path: '/empresa',
                    element: (
                        <ProtectedRoute roles={['M', 'U']}>
                            <Empresa />
                        </ProtectedRoute>
                    )
                },
                {
                    path: '/contrato',
                    element: (
                        <ProtectedRoute roles={['M', 'U']}>
                            <Contrato />
                        </ProtectedRoute>
                    )
                },
                {
                    path: '/usuario',
                    element: (
                        <ProtectedRoute roles={['M', 'U']}>
                            <Usuario />
                        </ProtectedRoute>
                    )
                },
                {
                    path: '/transmissor',
                    element: (
                        <ProtectedRoute roles={['M', 'E', 'U']}>
                            <Transmissor />
                        </ProtectedRoute>
                    )
                },
                {
                    path: '/faturamento',
                    element: (
                        <ProtectedRoute roles={['M', 'E', 'U']}>
                            <Faturamento />
                        </ProtectedRoute>
                    )
                },
                {
                    path: '/categoria',
                    element: <Categoria />
                },
                {
                    path: '/documento',
                    element: <Documento />
                },
                {
                    path: '/incluir-documento',
                    element: (
                        <ProtectedRoute roles={['M', 'C', 'U']}>
                            <IncluirDocumento />
                        </ProtectedRoute>
                    )
                },
                {
                    path: '/parceiros',
                    element: <Parceiros />
                },
                {
                    path: '/tutorial',
                    element: (
                        <ProtectedRoute roles={['M', 'E', 'U']}>
                            <Tutorial />
                        </ProtectedRoute>
                    )
                }
            ]
        },
        {
            path: '/confirmar-codigo',
            element: (
                <NavMotion>
                    <AuthGuard>
                        <ConfiramrCodigo />
                    </AuthGuard>
                </NavMotion>
            )
        }
    ]
};

export default MainRoutes;
