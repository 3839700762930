import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useAuth from 'hooks/useAuth';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { user } = useAuth();
    let filteredMenuItems = menuItem.items;
    if (user)
        filteredMenuItems = menuItem.items.map((group) => ({
            ...group,
            children: group.children.filter((item) => {
                if (item.protectedTo) {
                    return item.protectedTo.includes(user.funcao);
                }
                return true;
            })
        }));
    const navItems = filteredMenuItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
